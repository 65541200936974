import service from "@/plugins/axios"
import { useOidcStore } from "vue3-oidc";

const request = new service()
const source = '/api/';
const { state } = useOidcStore();

export const UPLOAD_IMAGE = (data: FormData, contextType: any, id: any) => {
    return request.axiosCall({
        method: `post`, url: `${source}` + "content/uploadimage", data: data,
        headers: {
            "Authorization" : `Bearer ${state.value.user?.access_token}`
        },
        params: {
            "contextType": contextType,
            "id": id,
        },
    })
}

export const GET_OWNER_IMAGE = (ownerId: any) => {
    return request.axiosCall({
        method: `get`, url: `${source}` + "content/ownerImage/" + `${ownerId}`,
        headers: {
            "Authorization" : `Bearer ${state.value.user?.access_token}`
        },
    })
}