import { computed, defineComponent, onMounted, ref } from "vue";
import json from '@/json/navigation.json'
import { RoleNameFields } from "@/base/core/enum";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import store from "@/store";

const jsonRef = ref(json);

export default defineComponent({
  name: "Navigation",
  watch: {
    '$route'(to, from) {
      checkURL();
    }
  },

  setup() {
    const $q = useQuasar();
    const list = jsonRef;
    const isRetailer = computed(() => store.state.ownerRole === RoleNameFields.retailer);

    onMounted(() => {
      // Temporary Solution
      if(isRetailer.value) {
        list.value[1] = {
          ...list.value[1],
          href: '/my_vault/shared-assets/'
        }
      } 
    });

    return {
      list
    }
  },
})

function checkURL() {
  let path = window.location.pathname;
  path = path.substring(path.indexOf("/") + 1);
  path = path.split("/", 1)[0];
  for (let i = 0; i < jsonRef.value.length; i++) {
    let jsonPath = jsonRef.value[i].href;
    jsonPath = jsonPath.substring(jsonPath.indexOf("/") + 1);
    jsonPath = jsonPath.split("/", 1)[0];
    if (jsonPath == path) {
      jsonRef.value[i].isActive = true;
    } else {
      jsonRef.value[i].isActive = false;
    }
  }
}