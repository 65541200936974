import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  reactive,
  ref,
  watchEffect,
} from "vue";
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useOidcStore } from "vue3-oidc";

export default defineComponent({
  name: "HeaderComponent",

  setup() {
    const brandImage = ref("/img/DeCS-Color@5x-1.png");
    const userEmail = ref();
    const userName = ref();
    const profileDialog = ref(false);
    const profileImage = computed(() => store.state.profileImage);
    const $q = useQuasar();
    const { state } = useOidcStore();
    watchEffect(() => {
      userEmail.value = state.value.user?.profile.email;
      userName.value = state.value.user?.profile.name;
    });

    onMounted(() => {});

    function dialogToggle() {
      profileDialog.value = !profileDialog.value;
    }

    async function logOut() {
      dialogToggle();
      store.dispatch("logOut");
    }

    return {
      brandImage,
      userEmail,
      userName,
      profileDialog,
      profileImage,
      dialogToggle,
      logOut,
    };
  },
});
