import service from "@/plugins/axios";
import { ContentModel } from "@/models/content";
import { ButtonModel } from "@/models/button";
import { useOidcStore } from "vue3-oidc";

const request = new service();
const source = "/mkapi/";
const { state } = useOidcStore();

export const GET_CONTENT = (contentModel: ContentModel) => {
  return request.axiosCall({
    method: `get`,
    url: `${source}` + "content/GetContent",
    headers: {
      "original-url": contentModel.originalUrl,
      Authorization: `Bearer ${state.value.user?.access_token}`,
    },
    params: {
      currentSiteId: contentModel.currentSiteId,
    },
  });
};

export const GET_CONTENT_WITH_PAGER = (
  contentModel: ContentModel,
  pageNum: any
) => {
  contentModel.originalUrl += `?set=${pageNum}`;
  return request.axiosCall({
    method: `get`,
    url: `${source}` + "content/GetContent",
    headers: {
      "original-url": contentModel.originalUrl,
      Authorization: `Bearer ${state.value.user?.access_token}`,
    },
    params: {
      currentSiteId: contentModel.currentSiteId,
    },
  });
};

export const GET_CONTENT_WITH_PAGER_SELF = (
  contentModel: ContentModel,
  pageNum: any
) => {
  contentModel.originalUrl += `&set=${pageNum}`;
  return request.axiosCall({
    method: `get`,
    url: `${source}` + "content/GetContent",
    headers: {
      "original-url": contentModel.originalUrl,
      Authorization: `Bearer ${state.value.user?.access_token}`,
    },
    params: {
      currentSiteId: contentModel.currentSiteId,
    },
  });
};

export const POST_CONTENT = (contentModel: ContentModel) => {
  return request.axiosCall({
    method: `post`,
    url: `${source}` + "content/PostContent",
    data: contentModel,
    headers: {
      "original-url": contentModel.originalUrl,
      Authorization: `Bearer ${state.value.user?.access_token}`,
    },
    params: {
      currentSiteId: contentModel.currentSiteId,
    },
  });
};

export const POST_CONTENT_AUTOPOSTBACK = (buttonModel: ButtonModel) => {
  return request.axiosCall({
    method: `post`,
    url: `${source}` + "content/PostContent",
    data: buttonModel,
    headers: {
      "original-url": buttonModel.originalUrl,
      Authorization: `Bearer ${state.value.user?.access_token}`,
    },
    params: {
      currentSiteId: buttonModel.currentSiteId,
    },
  });
};
