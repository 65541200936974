import service from "@/plugins/axios"
import { useOidcStore } from "vue3-oidc";

const request = new service()
const source = '/api/';
const { state } = useOidcStore();

export const GET_PROFILE_INFO = (originalUrl: any) => {
    return request.axiosCall({
        method: `get`, url: `${source}` + "user/info/",
        headers: {
            "Authorization" : `Bearer ${state.value.user?.access_token}`
        },
    })
}
