import service from "@/plugins/axios";
import { TopNavigationModel } from "@/models/topNavigation";
import { useOidcStore } from "vue3-oidc";

const request = new service();
const source = "/mkapi/";
const { state } = useOidcStore();

export const GET_TOP_NAVIGATION = (
  topNavigationModel: TopNavigationModel,
  groupType: any
) => {
  if (groupType == null) {
    return request.axiosCall({
      method: `get`,
      url: `${source}` + "navigation/GetTopnavigation",
      headers: {
        "original-url": topNavigationModel.originalUrl,
        Authorization: `Bearer ${state.value.user?.access_token}`,
      },
      params: {
        currentSiteId: topNavigationModel.currentSiteId,
      },
    });
  } else {
    return request.axiosCall({
      method: `get`,
      url: `${source}` + "navigation/GetTopnavigation",
      headers: {
        "original-url": topNavigationModel.originalUrl,
        Authorization: `Bearer ${state.value.user?.access_token}`,
      },
      params: {
        currentSiteId: topNavigationModel.currentSiteId,
        groupTag: groupType,
      },
    });
  }
};
